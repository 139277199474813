<template>
  <div>
    <div
      v-if="
        props.params.node?.group &&
        ['firstLevelGroup', 'secondLevelGroup'].includes(props.params.node?.field) &&
        !props.params?.data?.unMasterGroup
      "
      class="elv-ag-group-index"
      :style="{
        'padding-left': ['firstLevelGroup', 'secondLevelGroup'].includes(props.params.node?.field) ? '11px' : '35px'
      }"
    >
      <SvgIcon
        v-if="['firstLevelGroup', 'secondLevelGroup'].includes(props.params.node?.field)"
        class="elv-ag-group-arrow"
        name="group-arrow"
        width="16"
        height="16"
        :style="{ transform: rotate }"
        @click="onCheckGroupExpanded"
      />
      <div v-else class="elv-ag-group-empty" />
    </div>
    <div
      v-else-if="
        props.params.data?.entityAccount?.entityAccountId ||
        props.params.data?.entityAccount?.name ||
        props.params.data?.assetId ||
        props.params.data?.addressName ||
        props.params.data?.datetime ||
        props.params.data?.datetime === null ||
        props.params.data?.name ||
        props.params.data?.platformId ||
        props.params.data?.platformId === null ||
        (props.params.node?.field === 'thirdLevelGroup' && props.params?.value?.tableType === 'deFiPosition')
      "
      class="elv-ag-index"
    >
      <span v-if="props.params?.data?.unMasterGroup">
        {{ props.params?.data.rowIndex }}
      </span>
      <span
        v-else-if="
          props.params.value?.tableType !== 'contactsNamed' &&
          props.params.value?.tableType !== 'contactsUnnamed' &&
          props.params.value?.tableType !== 'transactions'
        "
      >
        {{
          props.params?.node.level === 0
            ? props.params?.node.rowIndex + 1
            : (props.params?.data.secondLevelRowIndex ??
              props.params.node?.rowIndex - props.params.node?.parent?.rowIndex)
        }}
      </span>
    </div>

    <div v-else-if="props.params.data?.type === 'total'" class="elv-financials-total">
      {{ props.params?.rowIndex > 21 ? t('message.reportOnlySee100') : t('message.reportOnlySee20') }}
      <span @click="onContactUs">{{ t('button.contactUs') }}</span>
    </div>

    <div v-else-if="props.params.node?.level === 2" class="elv-ag-group-index elv-table-group-rows"></div>

    <div v-else-if="props.params.node?.rowPinned === 'bottom'" class="elv-index-total">
      <span class="elv-index-total-text-total">{{ t('common.total') }}</span>
      <template v-if="props.params.data?.fieldValues?.count">
        <span class="elv-index-total-num">: {{ formatNumber(props.params.data?.fieldValues?.count) }} </span>
        <!-- <span class="elv-index-total-text">{{
          props.params.data?.fieldValues?.count <= 1 ? ` ${t('common.result')}` : ` ${t('common.results')}`
        }}</span> -->
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { formatNumber } from '@/lib/utils'

const { t } = useI18n()
const props: any = defineProps<{
  params: any
}>()

const rotate = ref('rotate(0deg)')

const onCheckGroupExpanded = () => {
  rotate.value = props.params?.node.expanded ? 'rotate(0deg)' : 'rotate(90deg)'
  props.params?.node.setExpanded(!props.params?.node.expanded)
}

const onContactUs = () => {
  window.open('https://www.elven.com/contacts', '_blank')
}

watch(
  [() => props.params?.node],
  () => {
    nextTick(() => {
      rotate.value =
        props.params?.node?.expanded || (props.params?.node?.group && props.params?.value?.tableType === 'derivative')
          ? 'rotate(90deg)'
          : 'rotate(0deg)'
      setTimeout(() => {
        if (rotate.value === 'rotate(0deg)' && props.params?.node?.expanded && props.params?.node?.group) {
          rotate.value = props.params?.node?.expanded ? 'rotate(90deg)' : 'rotate(0deg)'
        }
      }, 100)
    })
  },
  { immediate: true, deep: true }
)
</script>
<style lang="scss">
.elv-ag-index {
  font-family: 'Barlow';
  font-weight: 500;
  font-size: 13px;
  color: $elv-color-858B92;
  width: 35px;
  text-align: center;
  position: relative;

  &:hover {
    .elv-ag-index-select {
      display: flex;
    }
  }
}

.elv-ag-index-select {
  // display: v-bind('checkboxDisplay');
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 1px);
  height: calc(100% - 2px);
  position: absolute;
  top: 1px;
  left: 0px;
  // background: #f9fafb;

  .el-checkbox__label {
    display: none;
  }

  .el-checkbox {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #1e2024;
    margin-right: 0px;

    &.is-checked {
      .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #1e2024;
      }
    }

    &:not(.is-disabled):hover {
      .el-checkbox__inner {
        border-color: #1753eb;
        background-color: #fff;
      }
    }

    .el-checkbox__input.is-checked .el-checkbox__inner {
      border-color: #1753eb;
      background-color: #1753eb;

      &:after {
        left: 3.5px;
      }
    }

    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      border-color: #1753eb;
      background-color: #fff;

      &::before {
        height: 2.5px;
        top: 4.5px;
        background-color: #1753eb;
      }
    }
  }
}

.elv-financials-total {
  height: 80px;
  padding: 24px 0px 24px 34px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  color: $elv-color-1E2024;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 8px 20px;
    gap: 10px;
    width: 110px;
    height: 32px;
    background: $elv-theme-color;
    border-radius: 22px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $elv-color-FFFFFF;
    margin-left: 8px;
    cursor: pointer;
  }
}

.elv-index-total {
  padding-left: 16px;
  height: 52px;
  line-height: 52px;
}

.elv-ag-group-arrow {
  height: 40px;
  position: relative;
  top: 7px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.elv-index-total-num {
  font-family: 'Barlow';
  font-weight: 600 !important;
  font-size: 13px;
  line-height: 13px;
  color: $elv-color-858B92;
}

.elv-index-total-text-total {
  font-family: 'Plus Jakarta Sans' !important;
  color: $elv-color-858B92;
  font-weight: 700;
}

.elv-index-total-text {
  font-family: 'Plus Jakarta Sans' !important;
  font-weight: 600 !important;
  font-size: 12px;
  color: $elv-color-858B92;
  margin-left: 4px;
}
</style>
