<template>
  <div class="elv-financials-cell-currency-balance">
    <div class="elv-financials-cell-currency-balance__value">
      <div
        v-if="props.params.value?.cellName !== 'Other currencies'"
        :title="`${currencyData?.amount !== null ? formatNumber(currencyData?.amount, 20) : '-'}`"
      >
        {{ currencyData?.amount !== null ? formatNumber(currencyData?.amount ?? 0, 2) : '-' }}
      </div>
      <p
        v-if="currencyData?.amountFC !== null"
        :class="{ 'is-other': props.params.value?.cellName === 'Other currencies' }"
        :title="`${fieldValueFormat(
          currencyData?.amountFC ?? 0,
          {
            price: true,
            keepPoint: true,
            symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
          },
          'NUMBER'
        )}`"
      >
        {{
          fieldValueFormat(
            currencyData?.amountFC ?? 0,
            {
              dollar: true,
              symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            },
            'NUMBER'
          )
        }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { keyBy } from 'lodash-es'
import { useEntityStore } from '@/stores/modules/entity'
import { useAccountStore } from '@/stores/modules/accounts'

import { formatNumber, fieldValueFormat } from '@/lib/utils'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const { entityDetail } = storeToRefs(entityStore)
const { treasuryBalanceList } = storeToRefs(accountStore)

const currencyData = computed(() => {
  let currencyList: any = []
  if (props.params.node?.rowPinned === 'top') {
    currencyList = props.params?.value?.fieldValues?.currencyList
  }
  if (props.params.node?.level === 0) {
    currencyList = treasuryBalanceList.value.list?.[props.params?.data?.firstLevelIndex]?.currencyList ?? []
  }
  if (props.params.node?.level === 1) {
    currencyList =
      treasuryBalanceList.value.list?.[props.params?.data?.firstLevelIndex]?.list[props.params?.data?.secondLevelIndex]
        ?.currencyList ?? []
  }
  if (props.params.node?.level === 2) {
    currencyList =
      treasuryBalanceList.value.list?.[props.params?.data?.firstLevelIndex]?.list[props.params?.data?.secondLevelIndex]
        ?.list?.[props.params?.data?.thirdLevelIndex]?.currencyList ?? []
  }
  const newList = keyBy(currencyList, 'currency')
  return newList[props.params?.value?.symbol] ?? {}
})
</script>

<style lang="scss">
.elv-financials-cell-currency-balance {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  font-family: 'Barlow';
  position: relative;

  .elv-financials-cell-currency-balance__value {
    color: #0e0f11;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    text-align: right;

    p {
      color: #838d95;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;

      &.is-other {
        font-family: 'Barlow';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;
      }
    }
  }
}
</style>
